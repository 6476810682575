import { instagramLink, linkedinLink, resumeLink, youtubeLink } from "./links";

const linktreeExternalButtonData = [
  {
    title: "Follow me on LinkedIn",
    key: "follow-me-on-linkedin",
    link: linkedinLink,
  },
  {
    title: "Follow me on Instagram",
    key: "follow-me-on-instagram",
    link: instagramLink,
  },
  {
    title: "Check my main YouTube Channel",
    key: "check-my-main-youtube-channel",
    link: youtubeLink,
  },
  {
    title: "Check my latest YouTube video",
    key: "check-my-latest-youtube-video",
    link: "https://youtu.be/AodXbe8cHno",
  },
  {
    title: "Check my resume",
    key: "check-my-resume",
    link: resumeLink,
  },
];

export default linktreeExternalButtonData;
