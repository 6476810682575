import { createIcon } from "@chakra-ui/react";

export const Latex = createIcon({
  displayName: "Latex",
  viewBox: "0 0 1200 500",
  path: (
    <>
      <path
        fill="currentColor"
        d="M285.7 238h-11.25c-4.5 45.9-10.8 101.7-90 101.7H148c-21.15 0-22.05-3.15-22.05-18V82.75c0-15.3 0-21.6 42.3-21.6h14.85v-13.5C166.9 49 126.4 49 107.95 49 90.4 49 55.3 49 40 47.65v13.5h10.35C85 61.15 85.9 66.1 85.9 82.3v236.25c0 16.2-.9 21.15-35.55 21.15H40v13.95h233.55z"
      />
      <path
        fill="currentColor"
        d="M278.05 47.2c-1.8-5.4-2.7-7.2-8.55-7.2s-7.2 1.8-9 7.2l-72.45 183.6c-3.15 7.65-8.55 21.6-36.45 21.6v11.25h69.75V252.4c-13.95 0-22.5-6.3-22.5-15.3 0-2.25.45-3.15 1.35-6.3l15.3-38.7h89.1l18 45.9c.9 1.8 1.8 4.05 1.8 5.4 0 9-17.1 9-25.65 9v11.25h88.65V252.4h-6.3c-21.15 0-23.4-3.15-26.55-12.15L278.05 47.2zm-18 31.95l40.05 101.7H220z"
      />
      <path
        fill="currentColor"
        d="M638.5 50.35H364.45l-8.1 100.8h10.8c6.3-72.45 13.05-87.3 81-87.3 8.1 0 19.8 0 24.3.9 9.45 1.8 9.45 6.75 9.45 17.1V318.1c0 15.3 0 21.6-47.25 21.6h-18v13.95c18.45-1.35 63.9-1.35 84.6-1.35s67.05 0 85.5 1.35V339.7h-18c-47.25 0-47.25-6.3-47.25-21.6V81.85c0-9 0-15.3 8.1-17.1 4.95-.9 17.1-.9 25.65-.9 67.5 0 74.25 14.85 80.55 87.3h11.25l-8.55-100.8z"
      />
      <path
        fill="currentColor"
        d="M879.7 339.25h-11.25c-11.25 68.85-21.6 101.7-98.55 101.7h-59.4c-21.15 0-22.05-3.15-22.05-18v-119.7h40.05c43.65 0 48.6 14.4 48.6 52.65h11.25V237.1H777.1c0 38.25-4.95 52.2-48.6 52.2h-40.05V181.75c0-14.85.9-18 22.05-18h57.6c68.85 0 80.55 24.75 87.75 87.3h11.25l-12.6-100.8h-252v13.5h10.35c34.65 0 35.55 4.95 35.55 21.15v234.9c0 16.2-.9 21.15-35.55 21.15H602.5v13.95h258.3z"
      />
      <path
        fill="currentColor"
        d="M1015.15 180.4l61.65-90c9.45-14.4 24.75-28.8 64.8-29.25v-13.5h-107.1v13.5c18 .45 27.9 10.35 27.9 20.7 0 4.5-.9 5.4-4.05 10.35l-51.3 75.6-57.6-86.4c-.9-1.35-3.15-4.95-3.15-6.75 0-5.4 9.9-13.05 28.8-13.5v-13.5C959.8 49 926.95 49 909.85 49c-13.95 0-41.85-.45-58.5-1.35v13.5h8.55c24.75 0 33.3 3.15 41.85 15.75l82.35 124.65L910.75 310c-6.3 9-19.8 29.7-64.8 29.7v13.95h107.1V339.7c-20.7-.45-28.35-12.6-28.35-20.7 0-4.05 1.35-5.85 4.5-10.8l63.45-94.05 71.1 107.1c.9 1.8 2.25 3.6 2.25 4.95 0 5.4-9.9 13.05-29.25 13.5v13.95c15.75-1.35 48.6-1.35 65.25-1.35 18.9 0 39.6.45 58.5 1.35V339.7h-8.55c-23.4 0-32.85-2.25-42.3-16.2l-94.5-143.1z"
      />
    </>
  ),
});